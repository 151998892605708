<template>
  <dashboard />
</template>
<script>
import Dashboard from './dashboard/Index'
export default {
  components: {
    Dashboard,
  },
}
</script>

